<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="x_invoices"
                          columnsPrefix="incoming_invoice.x_invoice.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          title="common.menu.account-book.x_invoices"
                          :actions-list="getActions()"
                >
                    <template #cell(incoming_invoice_id)="row">
                        <a href="#" @click="$root.$children[0].openModal('incoming-invoices-modal', {
                        id: row.item.incoming_invoice_id,
                    }
                )">{{row.item.incoming_invoice_id}}</a>
                    </template>
                    <template #cell(invoice_data)="row">
                        <b-button size="sm"
                                  @click="$root.$children[0].openModal('json-modal', {data: row.item.invoice_data})"
                                  variant="info"
                                  class="mr-2"
                        >
                            <font-awesome-icon icon="eye"/>
                        </b-button>
                    </template>
                    <template #cell(actions)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                                    v-if="!row.item.incoming_invoice_id"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item
                                @click="createIncomingInvoice(row.item)"
                                v-if="!row.item.incoming_invoice_id"
                            >
                                <font-awesome-icon class="mr-2" icon="plus"/>
                                {{ $t('bank.actions.create_incoming_invoice') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import moment from "moment/moment";

export default {
    name: 'x_invoices',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('IncomingInvoices', ['getXInvoicesTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'IncomingInvoices/fetchXInvoices', 'getXInvoicesTable');
        },

        createIncomingInvoice(invoice) {
            let description = '';
            let vat = null;
            Object.keys(invoice.invoice_data.positions).forEach(key => {
                let position = invoice.invoice_data.positions[key]

                if (vat == null) {
                    vat = position['taxes'][0]['percent']
                }
                description += position['name'] + ': ' + position['billed_quantity'] + ' x ' + position['net_price_amount'] + "\n\r"
            })
            this.$root.$children[0].openModal('incoming-invoices-modal', {
                preview: {
                    "net_amount": invoice.invoice_data.line_total_amount,
                    "brutto_amount": invoice.invoice_data.grand_total_amount,
                    "invoice_date_timestamp": invoice.invoice_data.document_date,
                    "customer": invoice.seller_name,
                    "number": invoice.document_nr,
                    "leistung": description,
                    "vat": vat,
                    "type": "eingang",
                    "createdAt": moment(invoice.invoice_data.document_date).format('DD.MM.YYYY'),
                    "x_invoice_id": invoice.id,
                }
            }, this.refreshTable)
        },
        getActions() {
            return [
                {
                    title: this.$t('common.button.upload'),
                    icon: 'upload',
                    click: () => {
                        this.$root.$children[0].openModal('upload-file-modal', {store_dispatch_name: 'IncomingInvoices/uploadXInvoice'}, this.refreshTable, {width: '800px'});
                    },
                }
            ]
        },
    },
}
</script>